<template>
  <el-main>
    <div>
      <el-button type="primary" size="small" @click="addAddress">添加地址</el-button>
    </div>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="name" label="联系人姓名" align="center"></el-table-column>
      <el-table-column prop="contact_number" label="手机号码" align="center"></el-table-column>
      <el-table-column prop="combine_detail" label="省市区域" align="center"></el-table-column>
      <el-table-column prop="address" label="详细地址" align="center"></el-table-column>
      <el-table-column prop="refund_default" label="是否默认" align="center">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.refund_default" @change="defaultChange(scope.row, scope.$index)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="refund_default" label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
          <el-button @click="del(scope.$index)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="添加退货地址" :visible.sync="showAddaddress" width="35%">
      <el-form label-width="107px">
        <el-form-item label="* 联系人姓名：">
          <el-input v-model="name"></el-input>
        </el-form-item>
        <el-form-item label="* 联系电话：">
          <el-input v-model="contact_number"></el-input>
        </el-form-item>
        <el-form-item label="* 省市区域：">
          <div class="area">
            <el-select v-model="province_name" placeholder="请选择" @change="(val) => sonArea(0, val)">
              <el-option v-for="(item, index) in area" :key="index" :label="item.name" :value="item.name"></el-option>
            </el-select>
            <el-select v-model="city_name" placeholder="请选择" @change="(val) => sonArea(1, val)">
              <el-option v-for="(item, index) in cityArr" :key="index" :label="item.name" :value="item.name"></el-option>
            </el-select>
            <el-select v-model="area_name" placeholder="请选择" @change="(val) => sonArea(2, val)">
              <el-option v-for="(item, index) in areaArr" :key="index" :label="item.name" :value="item.name"></el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="* 详细地址：">
          <el-input v-model="address" type="textarea" :rows="8" placeholder="请输入详细地址"></el-input>
        </el-form-item>
        <el-form-item label="* 是否默认：">
          <el-switch v-model="refund_default"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddaddress = !1">取 消</el-button>
        <el-button type="primary" @click="comfirAddAddress">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import Paging from '@/components/paging'
export default {
  components: {
    Paging
  },
  data() {
    return {
      type: 0,
      page: 1,
      rows: 10,
      total_number: 0,
      list: [],
      currentInfo: {},
      area_name: '',
      province_name: '',
      city_name: '',
      area: [],
      cityArr: [],
      areaArr: [],
      showAddaddress: !1,
      address: '',
      name: '',
      contact_number: '',
      area_id: '',
      refund_default: 0,
    }
  },
  watch: {
    showAddaddress(val) {
      if (val && !this.area.length) this.getArea()
    }
  },
  created() {
    this.getList()
  },
  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.rows = val
        this.getList()
      } else {
        this.page = val
        this.getList()
      }
    },
    getList() {
      this.$axios
        .post(this.$api.set.address, {
          page: this.page,
          rows: this.rows
        })
        .then((res) => {
          if (res.code == 0) {
            let list = res.result.list
            for (let i in list) list[i].refund_default = list[i].refund_default ? !0 : !1
            this.list = list
            this.total_number = res.result.total_number
          } else {
            this.$message.error(res.msg)
          }
        })
    },
    addAddress() {
      this.showAddaddress = !0
      this.name = ''
      this.address = ''
      this.contact_number = ''
      this.area_name = ''
      this.province_name = ''
      this.city_name = ''
      this.refund_default = 0
      this.area_id = ''
    },
    edit(row) {
      this.showAddaddress = !0
      this.handleData(row)
    },
    //
    handleData(row) {
      this.type = 1
      this.currentInfo = row
      this.name = row.name
      this.address = row.address
      this.contact_number = row.contact_number
      this.area_id = row.area_id
      this.refund_default = row.refund_default
      if (this.area.length) {
        let combine_detail = this.currentInfo.combine_detail.split(' ')
        this.sonArea(0, combine_detail[0])
        this.sonArea(1, combine_detail[1])
        this.sonArea(2, combine_detail[2])
      }
    },
    del(index) {
      this.$confirm('确认删除此地址？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios
          .post(this.$api.set.delAddress, {
            id: this.list[index].id
          })
          .then((res) => {
            if (res.code == 0) {
              this.list.splice(index, 1)
              this.$message({
                message: '删除成功',
                type: 'success'
              })
            } else {
              this.$message.error(res.msg)
            }
          })
      })
    },
    defaultChange(row, index) {
      let refund_default = row.refund_default
      this.$axios.post(this.$api.set.setDefaultAddress, { id: row.id, refund_default: row.refund_default ? 1 : 0 }).then((res) => {
        if (res.code == 0) {
          this.list.map((item) => (item.refund_default = !1))
          this.list[index].refund_default = refund_default ? !0 : !1
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    comfirAddAddress() {
      try {
        if (!this.name) throw '请填写地址名称'
        if (!this.contact_number) throw '请填写联系电话'
        if (!this.area_id) throw '请选择省市区域'
        if (!this.address) throw '请填写详细地址'
        let url = this.type ? this.$api.set.editAddress : this.$api.set.addAddress
        let data = {
          name: this.name,
          address: this.address,
          contact_number: this.contact_number,
          area_id: this.area_id,
          refund_default: this.refund_default ? 1 : 0
        }
        if (this.type) data.id = this.currentInfo.id
        this.$axios.post(url, data).then((res) => {
          if (res.code == 0) {
            this.showAddaddress = !1
            this.getList()
            this.$message({
              message: this.type ? '编辑成功' : '添加成功',
              type: 'success'
            })
          } else {
            this.$message.error(res.msg)
          }
        })
      } catch (e) {
        this.$message({
          message: e,
          type: 'warning'
        })
      }
    },
    // 获取省市区信息
    getArea() {
      let that = this
      this.$axios
        .get(this.$api.shopDiy.areaList, {
          tree: 1,
          level: 2
        })
        .then((res) => {
          if (res.code == 0) {
            that.area = res.result.list
            if (this.type == 1) {
              let combine_detail = this.currentInfo.combine_detail.split(' ')
              this.sonArea(0, combine_detail[0])
              this.sonArea(1, combine_detail[1])
              this.sonArea(2, combine_detail[2])
            }
          }
        })
    },
    // 三级联动选择地区
    sonArea(status, val) {
      if (status == 0) {
        let index = this.area.find((item) => item.name == val)
        this.cityArr = index._child
        this.province_name = index.name
      } else if (status == 1) {
        let index = this.cityArr.find((item) => item.name == val)
        this.areaArr = index._child
        this.city_name = index.name
      } else if (status == 2) {
        let index = this.areaArr.find((item) => item.name == val)
        this.area_name = index.name
        this.area_id = index.id
      }
    }
  }
}
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .el-table {
    margin-top:10px;
  }
  .area {
    display: flex;
    justify-content: space-between;
    .el-select {
      margin-right: 10px;
      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
